package dev.moetz.twitcharchivemanager.design

import dev.moetz.materialize.mFooter
import react.ChildrenBuilder

fun ChildrenBuilder.mFooter() {
    mFooter(
        socialLink = "https://twitter.com/FlowMo_7",
        pageName = "TwitchArchiveManager",
        gitUrl = "https://gitlab.moetz.dev/twitch/twitch-archive-manager",
        isOpenSource = false,
    )
}
