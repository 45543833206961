package dev.moetz.twitcharchivemanager.util

import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import react.useEffect
import react.useState

fun <T> useStateFlow(flow: StateFlow<T>): T {
    val (state, setState) = useState(flow.value)

    //https://stackoverflow.com/questions/62690722/how-to-subscribe-to-stateflow-in-kotlin-react-useeffect
    useEffect {
        flow.onEach { setState(it) }.launchIn(this)
    }

    return state
}