package dev.moetz.twitcharchivemanager.page

import dev.moetz.materialize.mCol
import dev.moetz.materialize.mContainer
import dev.moetz.materialize.mHeader
import dev.moetz.materialize.mRow
import dev.moetz.twitcharchivemanager.design.mFooter
import react.FC
import react.Props
import react.dom.html.ReactHTML.p

external interface NotFoundPageServiceProps : Props {
}

val NotFoundPageService = FC<NotFoundPageServiceProps> { props ->
    mHeader(title = "TwitchArchiveManager")

    mContainer {
        mRow {
            mCol(small = 12) {
                p {
                    +"Page not found"
                }
            }
        }
    }

    mFooter()
}