package dev.moetz.twitcharchivemanager

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmInline

val Long.bytes get() = FileSize(this)

val Long.kiloBytes get() = FileSize(this * 1024)

val Long.megaBytes get() = FileSize(this * 1024 * 1024)

val Long.gigaBytes get() = FileSize(this * 1024 * 1024 * 1024)

@Serializable(with = FileSize.Serializer::class)
@JvmInline
value class FileSize(val bytes: Long) {

    val inBytes: Long get() = bytes

    val inWholeKiloBytes: Long get() = bytes / 1024

    val inWholeMegaBytes: Long get() = bytes / 1024 / 1024

    val inWholeGigaBytes: Long get() = bytes / 1024 / 1024 / 1024

    val inWholeTeraBytes: Long get() = bytes / 1024 / 1024 / 1024 / 1024

    fun toHumanReadableStringInMaxUnitRounded(): String {
        return if (inWholeKiloBytes == 0L) {
            "$inBytes Byte"
        } else if (inWholeMegaBytes == 0L) {
            "$inWholeKiloBytes KB"
        } else if (inWholeGigaBytes == 0L) {
            "$inWholeMegaBytes MB"
        } else if (inWholeTeraBytes == 0L) {
            "$inWholeGigaBytes GB"
        } else {
            "$inWholeTeraBytes TB"
        }
    }

    fun toHumanReadableStringInMaxUnitWithDecimals(decimalPlaces: Int = 3): String {
        require(decimalPlaces == 3) {
            "was too lazy to implement it nicely, sorry."
        }
        return if (inWholeKiloBytes == 0L) {
            "$inBytes Byte"
        } else if (inWholeMegaBytes == 0L) {
            val kiloBytes = inBytes / 1024.0
            val (beforeDecimal, afterDecimal) = kiloBytes.inBeforeAndAfterDecimalPlace(decimalPlaces)
            "${beforeDecimal}.${afterDecimal} KB"
        } else if (inWholeGigaBytes == 0L) {
            val megaBytes = inBytes / 1024.0 / 1024.0
            val (beforeDecimal, afterDecimal) = megaBytes.inBeforeAndAfterDecimalPlace(decimalPlaces)
            "${beforeDecimal}.${afterDecimal} MB"
        } else if (inWholeTeraBytes == 0L) {
            val gigaBytes = inBytes / 1024.0 / 1024.0 / 1024.0
            val (beforeDecimal, afterDecimal) = gigaBytes.inBeforeAndAfterDecimalPlace(decimalPlaces)
            "${beforeDecimal}.${afterDecimal} GB"
        } else {
            val teraBytes = inBytes / 1024.0 / 1024.0 / 1024.0 / 1024.0
            val (beforeDecimal, afterDecimal) = teraBytes.inBeforeAndAfterDecimalPlace(decimalPlaces)
            "${beforeDecimal}.${afterDecimal} TB"
        }
    }

    private fun Double.inBeforeAndAfterDecimalPlace(decimalPlaces: Int): Pair<Int, Int> {
        val multiplier = when (decimalPlaces) {
            0 -> 0
            1 -> 10
            2 -> 100
            3 -> 1000
            4 -> 10000
            5 -> 100000
            6 -> 1000000
            7 -> 10000000
            8 -> 100000000
            9 -> 1000000000
            else -> throw IllegalArgumentException("was too lazy to implement it correctly/nicely for $decimalPlaces decimal places, sorry")
        }
        val beforeDecimal = this.toInt()
        val afterDecimal = ((this - this.toInt()) * multiplier).toInt()
        return beforeDecimal to afterDecimal
    }


    object Serializer : KSerializer<FileSize> {
        override val descriptor: SerialDescriptor
            get() = PrimitiveSerialDescriptor("FileSize", PrimitiveKind.LONG)

        override fun deserialize(decoder: Decoder): FileSize {
            return FileSize(decoder.decodeLong())
        }

        override fun serialize(encoder: Encoder, value: FileSize) {
            encoder.encodeLong(value.bytes)
        }

    }

}

