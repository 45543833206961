package dev.moetz.materialize

import emotion.react.css
import js.array.asList
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.files.File
import react.ChildrenBuilder
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.footer
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.ul
import web.cssom.ClassName
import web.cssom.Color
import web.cssom.Cursor
import web.cssom.TextWrap
import web.html.InputType
import web.window.WindowTarget

fun showSuccessToast(message: String) {
    showToast(
        html = message,
        classes = "green-text"
    )
}

fun showErrorToast(message: String) {
    showToast(
        html = message,
        classes = "red-text"
    )
}

private fun showToast(html: String, classes: String) {
    val obj = Any().asDynamic()
    obj["html"] = html
    obj["classes"] = classes
    window.asDynamic().M.toast(obj)
}

fun ChildrenBuilder.mContainer(additionalClass: String? = null, block: ChildrenBuilder.() -> Unit) {
    div {
        className = if (additionalClass != null) {
            ClassName("container $additionalClass")
        } else {
            ClassName("container")
        }
        block.invoke(this)
    }
}

fun ChildrenBuilder.mSection(additionalClass: String? = null, block: ChildrenBuilder.() -> Unit) {
    div {
        className = if (additionalClass != null) {
            ClassName("section $additionalClass")
        } else {
            ClassName("section")
        }
        block.invoke(this)
    }
}

fun ChildrenBuilder.mRow(additionalClass: String? = null, block: ChildrenBuilder.() -> Unit) {
    div {
        className = if (additionalClass != null) {
            ClassName("row $additionalClass")
        } else {
            ClassName("row")
        }

        block.invoke(this)
    }
}

fun <T> ChildrenBuilder.mCollapsible(
    id: String,
    items: List<T>,
    headerBlock: ChildrenBuilder.(item: T) -> Unit,
    bodyBlock: ChildrenBuilder.(item: T) -> Unit,
    headerAdditionalClass: ((item: T) -> String?)? = null,
    bodyAdditionalClass: ((item: T) -> String?)? = null,
) {
    ul {
        className = ClassName("collapsible")
        this.id = id

        items.forEach { item ->
            li {
                div {
                    className = ClassName(
                        buildString {
                            append("collapsible-header")
                            headerAdditionalClass?.invoke(item)?.let { append(" $it") }
                        }
                    )

                    headerBlock.invoke(this, item)
                }
                div {
                    className = ClassName(
                        buildString {
                            append("collapsible-body")
                            bodyAdditionalClass?.invoke(item)?.let { append(" $it") }
                        }
                    )

                    bodyBlock.invoke(this, item)
                }
            }
        }
    }
}

fun ChildrenBuilder.mCol(
    small: Int? = null,
    medium: Int? = null,
    large: Int? = null,
    offsetSmall: Int? = null,
    offsetMedium: Int? = null,
    offsetLarge: Int? = null,
    additionalClass: String? = null,
    block: ChildrenBuilder.() -> Unit
) {
    div {
        className = ClassName(buildString {
            append("col")
            if (small != null) {
                append(" s$small")
            }
            if (medium != null) {
                append(" m$medium")
            }
            if (large != null) {
                append(" l$large")
            }
            if (offsetSmall != null) {
                append(" offset-s$offsetSmall")
            }
            if (offsetMedium != null) {
                append(" offset-m$offsetMedium")
            }
            if (offsetLarge != null) {
                append(" offset-l$offsetLarge")
            }
            if (additionalClass != null) {
                append(" $additionalClass")
            }
        })

        block.invoke(this)
    }
}

fun ChildrenBuilder.mA(
    href: String? = null,
    target: WindowTarget? = null,
    onClick: (() -> Unit)? = null,
    additionalClass: String? = null,
    block: ChildrenBuilder.() -> Unit
) {
    a {
        if (additionalClass != null) {
            className = ClassName(additionalClass)
        }
        if (href != null) {
            this.href = href
        }
        if (target != null) {
            this.target = target
        }
        if (onClick != null) {
            this.onClick = { onClick.invoke() }
        }
        block.invoke(this)
    }
}

fun ChildrenBuilder.mButton(
    text: String? = null,
    onClick: (() -> Unit)? = null,
    additionalClass: String? = null,
    block: (ChildrenBuilder.() -> Unit)? = null
) {
    a {
        className = ClassName(buildString {
            append("waves-effect waves-light btn")
            if (additionalClass != null) {
                append(" $additionalClass")
            }
        })
        if (onClick != null) {
            this.onClick = { onClick.invoke() }
        }
        if (text != null) {
            +text
        }
        block?.invoke(this)
    }
}


fun ChildrenBuilder.mLoadingCircle() {
    br()
    br()
    div {
        className = ClassName("preloader-wrapper big active")
        div {
            className = ClassName("spinner-layer spinner-blue-only")
            div {
                className = ClassName("circle-clipper left")
                div {
                    className = ClassName("circle")
                }
            }
            div {
                className = ClassName("gap-patch")
                div {
                    className = ClassName("circle")
                }
            }
            div {
                className = ClassName("circle-clipper right")
                div {
                    className = ClassName("circle")
                }
            }
        }
    }
    br()
    br()
}

fun ChildrenBuilder.mFooter(
    socialLink: String,
    pageName: String,
    gitUrl: String,
    isOpenSource: Boolean = true,
) {
    footer {
        className = ClassName("page-footer orange")

        div {
            className = ClassName("footer-copyright")
            mContainer {
                mRow {
                    mCol(large = 6, small = 6) {
//                        a {
//                            className = ClassName("orange-text text-lighten-3")
//                            href = "/info"
//                            +"Info"
//                        }
//
//                        br()

//                        a {
//                            className = ClassName("orange-text text-lighten-3")
//                            href = "/cookies"
//                            +"Cookie-Policy"
//                        }

                    }

                    mCol(large = 6, small = 6, additionalClass = "right-align") {
                        mA(
                            href = socialLink,
                            target = WindowTarget._blank,
                            additionalClass = "orange-text text-lighten-3"
                        ) {
                            +"made with "
                        }

                        span {
                            className = ClassName("red-text")
                            +"❤"
                        }

                        br()

                        a {
                            className = ClassName("orange-text text-lighten-3")
                            href = "/"
                            +pageName
                        }
                        +" is "

                        a {
                            className = ClassName("orange-text text-lighten-3")
                            href = gitUrl
                            target = WindowTarget._blank
                            if (isOpenSource) {
                                +"open sourced here"
                            } else {
                                +"close sourced here"
                            }
                        }
                    }
                }
            }
        }
    }
}


data class MaterializeTab(
    val label: String,
    val active: Boolean,
    val href: String? = null,
    val onClick: (() -> Unit)? = null,
)

fun ChildrenBuilder.mHeader(
    title: String,
    tabs: List<MaterializeTab> = emptyList(),
    fixed: Boolean = false,
    connected: Boolean = true,
) {
    val content: ChildrenBuilder.() -> Unit = {
        ReactHTML.nav {
            className = ClassName("blue darken-1 nav-extended")

            div {
                className = ClassName("container")

                div {
                    className = ClassName("nav-wrapper")
                    a {
                        id = "logo-container"
//                        href = "/"
                        className = ClassName("brand-logo")
                        span {
                            css {
                                if (connected.not()) {
                                    color = Color("red")
                                }
                                textWrap = TextWrap.nowrap
                            }
                            +title
                        }
                    }
                }

                if (tabs.isNotEmpty()) {
                    div {
                        className = ClassName("nav-content")

                        ul {
                            className = ClassName("tabs tabs-transparent")

                            tabs.forEach { tab ->
                                li {
                                    className = ClassName("tab")

                                    a {
                                        if (tab.active) {
                                            className = ClassName("active")
                                        }
                                        href = tab.href
                                        +tab.label
                                        if (tab.onClick != null) {
                                            onClick = {
                                                tab.onClick.invoke()
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    if (fixed) {
        div {
            className = ClassName("navbar-fixed")
            content.invoke(this)
        }
    } else {
        content.invoke(this)
    }
}

fun mCollapsibleInit(vararg collapsibleIds: String) {
    val elements = collapsibleIds.map { document.getElementById(it) }
    window.asDynamic().M.Collapsible.init(elements)
}

fun mFormSelectInit(vararg selectIds: String) {
    val elements = selectIds.map { document.getElementById(it) }
    window.asDynamic().M.FormSelect.init(elements)
}

fun ChildrenBuilder.mFileInput(
    id: String,
    buttonLabel: String,
    placeholder: String,
    accept: String? = null,
    onSelected: (List<File>) -> Unit
) {
    div {
        className = ClassName("file-field input-field")

        div {
            className = ClassName("btn")
            span { +buttonLabel }
            input {
                this.id = id
                type = InputType.file
                this.accept = accept
                onChange = { event ->
                    onSelected.invoke(event.target.files?.asList()?.map { it as File }.orEmpty())
                }
            }
        }
        div {
            className = ClassName("file-path-wrapper")
            input {
                className = ClassName("file-path validate")
                this.id = "${id}_text"
                type = InputType.text
                this.placeholder = placeholder
            }
        }
    }
}

fun ChildrenBuilder.mDivider() {
    div {
        className = ClassName("divider")
    }
}


data class MaterialCollectionItem(
    val label: String,
    val href: String? = null,
    val onClick: (() -> Unit)? = null,
    val active: Boolean = false,
    val disabled: Boolean = false,
)

fun ChildrenBuilder.mCollection(
    items: List<MaterialCollectionItem>,
) {
    div {
        className = ClassName("collection")
        items.forEach { item ->
            a {
                css(ClassName(if (item.active) "collection-item active" else "collection-item")) {
                    if (item.href == null && item.onClick != null) {
                        cursor = Cursor.pointer
                    }
                    if (item.disabled) {
                        backgroundColor = Color("#292929")
                    }
                }
                if (item.href != null) {
                    href = item.href
                }
                if (item.onClick != null) {
                    onClick = { item.onClick.invoke() }
                }
                +item.label
            }
        }
    }
}


data class MaterialCardAction(
    val label: String,
    val href: String? = null,
    val onClick: (() -> Unit)? = null,
    val color: String = "orange-text",
    val disabled: Boolean = false,
)

fun ChildrenBuilder.mCard(
    title: String,
    cardColor: String = "blue-grey darken-1",
    contentColor: String = "white-text",
    content: ChildrenBuilder.() -> Unit,
    actions: List<MaterialCardAction>,
) {
    div {
        className = ClassName("card $cardColor")

        div {
            className = ClassName("card-content $contentColor")
            span {
                className = ClassName("card-title")
                +title
            }
            content.invoke(this)
        }
        if (actions.isNotEmpty()) {
            div {
                className = ClassName("card-action")

                actions.forEach { action ->
                    a {
                        css(ClassName(action.color)) {
                            if (action.onClick != null) {
                                cursor = Cursor.pointer
                            }
                            if (action.disabled) {
                                color = Color("gray")
                            }
                        }
                        if (action.href != null) {
                            href = action.href
                        }
                        if (action.onClick != null) {
                            onClick = {
                                action.onClick.invoke()
                            }
                        }
                        +action.label
                    }
                }
            }
        }
    }
}
