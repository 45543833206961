package dev.moetz.twitcharchivemanager.util

import kotlinx.datetime.LocalDateTime
import kotlin.time.Duration

internal fun Int.fixedDigitString(digits: Int): String = this.toLong().fixedDigitString(digits)

internal fun Long.fixedDigitString(digits: Int): String {
    val string = this.toString()
    return if (string.length >= digits) {
        string
    } else {
        (0 until (digits - string.length)).joinToString(separator = "", postfix = string) { "0" }
    }
}

internal fun LocalDateTime.formatHumanReadable(): String {
    val dateTime = this
    return buildString {
        append(dateTime.dayOfMonth.fixedDigitString(2))
        append(". ")
        append(dateTime.monthNumber.fixedDigitString(2))
        append(". ")
        append(dateTime.year.fixedDigitString(4))
        append(", ")
        append(dateTime.hour.fixedDigitString(2))
        append(":")
        append(dateTime.minute.fixedDigitString(2))
    }
}

internal fun Duration.formatHumanReadable(): String {
    return buildString {
        append(this@formatHumanReadable.inWholeHours.fixedDigitString(2))
        append(':')
        append((this@formatHumanReadable.inWholeMinutes % 60).fixedDigitString(2))
        append(':')
        append((this@formatHumanReadable.inWholeSeconds % 60).fixedDigitString(2))
    }
}