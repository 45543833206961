package dev.moetz.twitcharchivemanager.model

import dev.moetz.twitcharchivemanager.FileSize
import kotlinx.serialization.Serializable

@Serializable
data class StorageInfo(
    val free: FileSize,
    val total: FileSize,
)
