package dev.moetz.twitcharchivemanager.model

import dev.moetz.twitcharchivemanager.FileSize
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class ArchiveItem(
    val id: Long,
    val title: String,
    val description: String?,
    val createdAt: Instant?,
    val publishedAt: Instant?,
    val duration: Long,
    val chapters: List<Chapter>,
    val sizeInfo: SizeInfo,
    val hasChat: Boolean,
    val folderName: String,
) {
    @Serializable
    data class Chapter(
        val description: String,
        val type: String,
        val segment: Segment,
    ) {
        @Serializable
        data class Segment(
            val position: Double,
            val duration: Double,
        )
    }

    @Serializable
    data class SizeInfo(
        val vodFileSize: FileSize,
        val readableChatFileSize: FileSize?,
        val verboseChatFileSize: FileSize?,
    )
}
