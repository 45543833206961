package dev.moetz.twitcharchivemanager.util

import dev.moetz.reconnectingwebsocket.ReconnectingWebSocketClient
import io.ktor.client.*
import io.ktor.client.plugins.logging.*
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.launch
import kotlinx.serialization.KSerializer
import kotlinx.serialization.json.Json
import org.w3c.dom.get


abstract class ClientServiceManager<State>(
    websocketPath: String,
    initialState: State,
    private val stateSerializer: KSerializer<State>,
    private val localStorageConfig: LocalStorageConfig? = null,
    protected val json: Json = Json { ignoreUnknownKeys = true },
    protected val scope: CoroutineScope = GlobalScope
) {

    data class LocalStorageConfig(
        val key: String
    )

    protected fun getFullWebsocketUrlForPath(path: String): String {
        return buildString {
            val hostname = window.location.hostname
            val port = window.location.port
            val isSecure = window.location.protocol == "https:"
            if (isSecure) {
                append("wss://")
            } else {
                append("ws://")
            }
            append(hostname)
            if (port.isBlank().not()) {
                append(':')
                append(port)
            }
            if (path.startsWith('/').not()) {
                append('/')
            }
            append(path)
        }
    }

    fun getFullUrl(path: String): String {
        return buildString {
            val hostname = window.location.hostname
            val port = window.location.port
            val isSecure = window.location.protocol == "https:"
            if (isSecure) {
                append("https://")
            } else {
                append("http://")
            }
            append(hostname)
            if (port.isBlank().not()) {
                append(':')
                append(port)
            }
            if (path.startsWith('/').not()) {
                append('/')
            }
            append(path)
        }
    }

    private val websocketClient = ReconnectingWebSocketClient(
        url = getFullWebsocketUrlForPath(websocketPath),
        startToRetryInstantly = true
    )

    private val mutableConnectedStateFlow: MutableStateFlow<Boolean> = MutableStateFlow(false)
    val connectedStateFlow: StateFlow<Boolean> get() = mutableConnectedStateFlow.asStateFlow()

    private val mutableStateFlow: MutableStateFlow<State> = MutableStateFlow(initialState)
    val stateAsStateFlow: StateFlow<State> get() = mutableStateFlow.asStateFlow()

    init {
        if (localStorageConfig != null) {
            try {
                val fromLocalStorage = localStorage.get(localStorageConfig.key)
                    ?.takeIf { it.isNotBlank() }
                    ?.let {
                        json.decodeFromString(stateSerializer, it)
                    }
                if (fromLocalStorage != null) {
                    mutableStateFlow.value = fromLocalStorage
                    scope.launch {
                        onRemoteStateReceived(fromLocalStorage)
                    }
                }
            } catch (throwable: Throwable) {
                println("Error reading local storage: ${throwable.message}")
                localStorage.removeItem(localStorageConfig.key)
            }
        }
        websocketClient.connected
            .onEach { mutableConnectedStateFlow.value = it }
            .launchIn(scope)

        websocketClient.received
            .map { encoded -> json.decodeFromString(stateSerializer, encoded) }
            .onEach { mutableStateFlow.value = it }
            .onEach { onRemoteStateReceived(it) }
            .launchIn(scope)

        scope.launch {
            websocketClient.connect()
        }
    }

    protected val httpClient: HttpClient by lazy {
        HttpClient() {
            install(Logging)
        }
    }

    open suspend fun onRemoteStateReceived(state: State) {
        if (localStorageConfig != null) {
            localStorage.setItem(localStorageConfig.key, json.encodeToString(stateSerializer, state))
        }
    }

    suspend fun send(message: String) {
        websocketClient.send(message)
    }

}